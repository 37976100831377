<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-layout wrap justify-center>
      <v-flex xs11 sm10>
        <v-layout wrap pt-8 v-if="courseView">
          <v-flex xs12 sm3>
            <v-img height="200px" :src="mediaURL + courseView.image"> </v-img>
          </v-flex>
          <v-flex xs12 sm9 px-10 pt-2 text-left>
            <span style="font-family: poppinsbold; color: #000000; font-size: 24px">{{ courseView.courseName }}</span>
            <br />
            <br />
            <span style="font-family: poppinsregular" v-html="courseView.description"></span>
          </v-flex>
          <v-layout wrap pt-5>
            <v-flex xs12 pr-5>
              <v-tabs v-model="tab" background-color="#DFFFDBCC" color="#3CB043" dark grow>
                <v-tab v-for="(item, i) in items" :key="i">
                  <span style="font-family: poppinsmedium" :style="tab == i ? 'color: black' : 'color: #635E5E'">
                    {{ item }}</span>
                </v-tab>
                <v-tab-item>
                  <v-layout wrap py-10>
                    <v-flex xs12>
                      <v-card outlined>
                        <v-layout wrap justify-start pb-10 pa-10 pt-5>
                          <v-flex xs12 sm10 text-left py-5>
                            <span style="
                                color: #000000;
                                font-family: poppinssemibold;
                                font-size: 20px;
                              ">Course Modules</span>
                          </v-flex>
                          <v-flex xs12 sm2>
                            <v-btn outlined color="#3CB043" @click="dialogMaterials = true"><v-img contain height="30px"
                                src="./../../assets/images/book2.png">
                              </v-img><span style="
                                  font-family: opensanssemibold;
                                  text-transform: none;
                                  color: #000000;
                                ">&nbsp; Materials
                              </span></v-btn>
                          </v-flex>
                          <v-expansion-panels flat v-model="panel" :disabled="disabled" multiple>
                            <v-expansion-panel v-for="(list, l) in modules" :key="l">
                              <v-expansion-panel-header color="#F7F7F7">
                                <span style="
                                    color: #000000;
                                    font-family: poppinsmedium;
                                    font-size: 17px;
                                  ">Module {{ list.moduleNo }} :
                                  {{ list.moduleName }}</span>
                                <template v-slot:actions>
                                  <v-icon color="#3CB043"> $expand </v-icon>
                                </template>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-layout wrap justify-center>
                                  <v-flex xs12 sm10 py-5 px-0 text-left>
                                    <span style="
                                        color: #000000;
                                        font-family: poppinsregular;
                                        font-size: 14px;
                                      " v-html="list.moduleDetails"></span>
                                  </v-flex>
                                  <!-- <v-flex xs1 sm2 text-right>
                          <v-btn
                            dark
                            color="#3CB043"
                            :to="'/examSection?id=' + list._id"
                            ><span>Attend Exam</span>
                          </v-btn>
                        </v-flex> -->
                                </v-layout>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-tab-item>
                <v-tab-item>
                  <v-layout wrap py-10>
                    <v-flex xs12>
                      <v-card outlined>
                        <v-layout wrap pb-16 px-10>
                          <v-flex xs12 text-left py-10>
                            <span style="
                                color: #000000;
                                font-family: poppinssemibold;
                                font-size: 20px;
                              ">Learning Outcomes:</span>
                          </v-flex>
                          <v-flex xs12 px-8 text-left>
                            <span style="
                                color: #000000;
                                font-family: poppinsregular;
                                font-size: 14px;
                              " v-html="courseView.learningOutComes"></span>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-tab-item>
                <v-tab-item>
                  <v-layout wrap py-10>
                    <v-flex xs12>
                      <v-card outlined>
                        <v-layout wrap pb-10 pt-5 px-10>
                          <v-flex xs12 text-left py-5>
                            <span style="
                                color: #000000;
                                font-family: poppinssemibold;
                                font-size: 20px;
                              ">Who Should Attend :</span>
                          </v-flex>
                          <v-flex xs12 py-2 px-8 text-left>
                            <span style="
                                color: #000000;
                                font-family: poppinsregular;
                                font-size: 14px;
                              " v-html="courseView.whoShouldAttend"></span>
                          </v-flex>
                          <v-flex xs12 text-left py-5>
                            <span style="
                                color: #000000;
                                font-family: poppinssemibold;
                                font-size: 20px;
                              ">Assessment & Certification</span>
                          </v-flex>
                          <v-flex xs12 py-2 px-8 text-left>
                            <span style="
                                color: #000000;
                                font-family: poppinsregular;
                                font-size: 14px;
                              " v-html="courseView.assesmentAndEvaluation"></span>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-tab-item>
              </v-tabs>
              <v-layout wrap justify-center pb-10>
                <v-flex xs12>
                  <v-card outlined>
                    <v-layout wrap justify-center pa-10>
                      <v-flex xs12 text-left>
                        <span style="
                            color: #000000;
                            font-family: poppinssemibold;
                            font-size: 20px;
                          ">Examinations</span>
                      </v-flex>
                      <v-layout wrap v-if="examSection.length > 0">
                        <v-flex xs12 pt-5 v-for="(ex, e) in examSection" :key="e">
                          <v-card tile flat color="#F7F7F7">
                            <v-layout wrap py-5 justify-center px-10>
                              <v-flex xs12 sm8 text-left>
                                <span style="font-family: poppinsregular">{{ ex.courseName }} Test</span>
                              </v-flex>
                              <v-flex xs6 sm2 text-right pt-2>
                                <v-icon size="20" color="#3CB043">mdi-clock-outline</v-icon>
                                <span style="
                                    font-family: opensanssemibold;
                                    color: #3cb043;
                                  ">
                                  &nbsp; 45 mins</span>
                              </v-flex>
                              <!-- v-if="ex.examActivated == true" -->
                              <v-flex xs6 sm2 text-right>
                                <v-layout wrap v-if="ex.examActivated == true && ex.isExamAttempted==false">
                                  <v-flex>
                                    <v-btn dark color="#3CB043" :to="'/rules?courseId=' + ex._id + '&id=' + courseView._id"><span style="
                                      text-transform: none;
                                      font-family: opensanssemibold;
                                    ">Take a test</span></v-btn>
                                  </v-flex>
                                </v-layout>
                                <v-layout wrap v-if="ex.isExamAttempted==true">
                                  <v-flex pt-2>
                                    <span style="font-family: poppinsbold; font-size: 14px">Already Attend Exam</span>
                                    </v-flex>
                                    </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </v-flex>
                      </v-layout>
                      <v-layout wrap justify-center v-else>
                        <v-flex xs12 py-16 text-center>
                          <span style="font-family: poppinsbold; font-size: 25px">You can't attend exam now !</span>
                        </v-flex>
                      </v-layout>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-layout>
        <v-dialog v-model="dialogMaterials" width="1000px" persistent>
          <v-layout wrap px-4 justify-end style="background-color: white">
            <v-flex xs1 pt-4 align-self-start @click="dialogMaterials = false">
              <v-icon>mdi-close-circle</v-icon>
            </v-flex>
            <v-flex xs12 text-left align-self-center pt-5 pb-10 px-5>
              <span style="font-family: poppinsmedium; font-size: 25px; color: #000000">Course Materials</span>
              <v-layout wrap pt-2>
                <v-flex xs12 text-left py-2 v-for="(list, l) in examSection" :key="l">
                  <v-card tile flat color="#F7F7F7">
                    <v-layout wrap py-5 justify-center px-5>
                      <v-flex xs12 sm6 text-left>
                        <span style="font-family: poppinsregular">{{ list.courseName }} Test</span>
                      </v-flex>
                      <v-flex xs6 sm3 text-right pt-2>
                        <v-btn v-if="list.textBook"  color="#3CB043" dark download
                          :href="ipURL + '/file/get/' + list.textBook" target="_blank"><span
                            style="text-transform: none; font-family: poppinsmedium; font-size: 12px;">Download Test Book</span></v-btn>
                      </v-flex>
                      <v-flex xs6 sm3 pt-2 text-right>
                        <a target="_blank" style="text-decoration: none !important" :href="courseView.studyMaterialLink">
                          <v-btn color="#3CB043" dark><span style="text-transform: none; font-family: poppinsmedium; font-size: 12px;">Open Stady Material
                              Link</span></v-btn>
                        </a>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      tab: null,
      items: ["Curriculum", "Outcomes", "Audience & Certification"],
      courseView: "",
      examSection: [],
      modules: [],
      popular: [],
      guestid: "",
      appLoading: false,
      ServerError: false,
      cart: [],
      textBook: "",
      panel: [0],
      disabled: false,
      dialogMaterials: false,
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/user/purchase/course/view",
        method: "POST",
        data: {
          courseId: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.courseView = response.data.data;
          this.textBook = this.courseView.textBook;
          this.examSection = response.data.includedCourse;
          this.modules = response.data.modules;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped></style>